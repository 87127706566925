<template>
    <div id="app">
        <!-- <img src="192.168.0.121:18004/qsApi/mini/sport/203" alt=""> -->
        <!-- <input type="text" v-model="message" />
        <input type="button" value="websocket发送" @click="websocketSend" /> -->
        <iframe :src="result" frameborder="0"></iframe>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            websock: null,
            message: "",
            result: "",
            userId: "",
            para: null,
        };
    },
    mounted() {
        var url = document.location.toString();
        var arrUrl = url.split("=");
        this.para = arrUrl[1];
        this.initWebSocket();
    },
    
    methods: {
        initWebSocket() {
            if (typeof WebSocket === "undefined") {
                alert("您的浏览器不支持socket");
            } else {
                var url = "wss:www.iloverend.com/qsApiV1/webSocket/sport/"+this.para;
                this.websock = new WebSocket(url);
                this.websock.onopen = this.websocketOnopen;
                this.websock.onerror = this.websocketOnerror;
                this.websock.onmessage = this.websocketOnmessage;
                this.websock.onclose = this.websocketOnclose;
            };
        },
        websocketOnopen: function () {
            console.log("WebSocket连接成功");
        },
        websocketOnerror: function (e) {
            console.log("WebSocket连接发生错误");
            this.reconnect();
        },
        websocketOnmessage: function (e) {
            console.log("监听关闭" + e);
            console.log("-----接收消息-------", e.data);
            if(e.data !== "通知消息") {
                this.result = e.data;
            }
            // var data = eval("(" + e.data + ")"); //解析对象
        },
        websocketOnclose: function (e) {
            console.log("connection closed (" + e.code + ")");
            this.reconnect();
        },
        websocketSend() {
            // 数据发送
            try {
                this.websock.send(this.message);
            } catch (err) {
                console.log("send failed (" + err.code + ")");
            }
        },
        send() {
            console.log("发送消息");
            // 接口方式-数据发送
            // sendMessage({ userId: "2", message: this.message }).then((res) => {
            //     if (res.status === "success") {
            //         this.$message.success("发送成功");
            //     } else {
            //         this.$message.error("发送失败");
            //     }
            // });
        },
        reconnect() {
            var that = this;
            if (that.lockReconnect) return;
            that.lockReconnect = true;
            //没连接上会一直重连，设置延迟避免请求过多
            setTimeout(function () {
                console.info("尝试重连...");
                that.initWebSocket();
                that.lockReconnect = false;
            }, 5000);
        },
    }
}
</script>

<style>
html,
body,
#app {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
}

#app {
    background-image: url("./assets/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

iframe {
    width: 100%;
    height: 100%;
}
</style>
